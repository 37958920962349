export const survey = { 
    "description": "问卷备注", 
    "position": "排序", 
    "status": "是否启用", 
    "title": "问卷标题",
    "creator": "创建者",
    "preview": "预览",
    "import": "导入问卷",
    "prompt": "表格中缺少必要的表头信息",
    "enabled": "启用",
    "disabled": "禁用"
}