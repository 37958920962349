import service from '@/utils/request'

// 登录
export const login = (data) => {
    return service({
        url: '/app/login',
        method: 'post',
        data: data
    })
}

// 获取验证码
export const getCaptcha = (data) => {
    return service({
        url: '/captcha',
        method: 'post',
        data: data
    })
}

export const register = (data) => {
    return service({
        url: '/app/register',
        method: 'post',
        data: data
    })
}

export const getUserProfile = () => {
    return service({
        url: '/user/profile',
        method: 'get'
    })
}

// 获取用户列表
export const search = (params) => {
    return service({
        url: '/users',
        method: 'get',
        params
    })
}

// 获取指定用户的详情
export const get = (params) => {
    return service({
        url: `/user/${params.id}`,
        method: 'get'
    })
}

// 创建用户
export const create = (data) => {
    return service({
        url: '/user',
        method: 'post',
        data
    })
}

// 更新用户信息
export const update = (data) => {
    return service({
        url: `/user/${data.id}`,
        method: 'put',
        data
    })
}

// 删除用户
export const remove = (data) => {
    return service({
        url: `/user/${data.id}`,
        method: 'delete',
        data
    })
}

// 更新用户密码
export const userProfile = (data) => {
    return service({
        url: `/user/profile`,
        method: 'post',
        data
    })
}

// 更新用户密码
export const updatePassword = (data) => {
    return service({
        url: `/user/password`,
        method: 'post',
        data
    })
}

// 更新自己信息
export const updateUser = (data) => {
    return service({
        url: '/user/update',
        method: 'post',
        data
    })
}