export const login = {
    title: "系统登录",
    login: "登    录",
    email_placeholder: "请输入邮箱账号",
    password_placeholder: "请输入密码",
    logout: "退出登录",
    captcha_placeholder: "请输入验证码",
    success: "登录成功",
    fail: "登录失败",
    forget_password: "忘记密码?"
}