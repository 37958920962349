import { createRouter, createWebHashHistory } from 'vue-router'

/* Layout */
import Layout from '@/layout'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [{
      path: 'dashboard',
      name: 'Dashboard',
      component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/index.vue'),
      meta: { title: 'Dashboard', icon: 'dashboard' }
    }]
  },
  {
    path: '/questionnaire',
    component: Layout,
    name: 'Questionnaire',
    redirect: 'noRedirect',
    meta: { title: 'Questionnaire', icon: 'documentation' },
    children: [
      {
        path: 'survey',
        component: () => import(/* webpackChunkName: "questionnaire" */ '@/views/survey/index'),
        name: 'Survey',
        meta: { title: 'Survey', icon: '' }
      }, {
        path: 'question',
        component: () => import(/* webpackChunkName: "questionnaire" */ '@/views/question/index'),
        name: 'Question',
        meta: { title: 'Question', icon: '' }
      }, {
        path: 'answer',
        component: () => import(/* webpackChunkName: "questionnaire" */ '@/views/answer/index'),
        name: 'Answer',
        meta: { title: 'Answer', icon: '' }
      }]
  },
  {
    path: '/employees',
    component: Layout,
    redirect: '/employees/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/employees/index'),
        name: 'Employees',
        meta: { title: 'Employees', icon: 'peoples' }
      }
    ]
  },
  {
    path: '/inference_rule',
    component: Layout,
    redirect: '/inference_rule/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/inferenceRule/index'),
        name: 'InferenceRule',
        meta: { title: 'InferenceRule', icon: 'component' }
      }
    ]
  },
  {
    path: '/illness',
    component: Layout,
    redirect: '/illness/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/illness/index'),
        name: 'Illness',
        meta: { title: 'Illness', icon: 'list' }
      }
    ]
  },
  // {
  //   path: '/action_guide',
  //   component: Layout,
  //   redirect: '/action_guide/index',
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/actionGuide/index'),
  //       name: 'ActionGuide',
  //       meta: { title: 'ActionGuide', icon: 'list' }
  //     }
  //   ]
  // },
  {
    path: '/admin',
    component: Layout,
    name: 'SuperAdmin',
    redirect: 'noRedirect',
    meta: { title: 'SuperAdmin', icon: 'user' },
    children: [{
      path: 'user',
      name: 'User',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/superAdmin/user'),
      meta: { title: 'User', icon: '' }
    }, {
      path: 'authority',
      name: 'Authority',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/superAdmin/authority'),
      meta: { title: 'Authority', icon: '' }
    },
    // {
    //   path: 'api',
    //   name: 'Api',
    //   component: () => import(/* webpackChunkName: "admin" */ '@/views/superAdmin/api'),
    //   meta: { title: 'Api', icon: '' }
    // }, 
    // {
    //   path: 'menus',
    //   name: 'Menus',
    //   component: () => import(/* webpackChunkName: "admin" */ '@/views/superAdmin/menus'),
    //   meta: { title: 'Menus', icon: '' }
    // }, 
    {
      path: 'password',
      name: 'Password',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/superAdmin/password'),
      meta: { title: 'Password', icon: '' }
    }]
  },
  {
    path: '/system',
    component: Layout,
    redirect: '/system/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/profile/index.vue'),
        name: 'Profile',
        meta: { title: 'Profile', icon: 'form' }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login/index.vue')
  },
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
