import { route } from './route'
import { common } from './common'
import { illness } from './ui/illness'
import { answer } from './ui/answer'
import { employees } from './ui/employees'
import { survey } from './ui/survey'
import { question } from './ui/question'
import { menus } from './ui/menus'
import { user } from './ui/user'
import { api } from './ui/api'
import { authority } from './ui/authority'
import { login } from './ui/login'
import { profile } from './ui/profile'
import { interfence_rule } from './ui/interfence_rule'

export default {
	interfence_rule,
	illness,
	profile,
	answer,
	employees,
	survey,
	question,
	menus,
	user,
	api,
	authority,	
    route,
    login,
    common
}