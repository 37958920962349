import { login, getUserProfile } from '@/api/user'
import { setToken, getToken } from '@/utils/auth'
// import router from '@/router/index'

export const user = {
    namespaced: true,
    state: {
        userInfo: {
            id: "",
            name: "",
            email: "",
            mobile: ""
        },
        token: getToken
    },
    mutations: {
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo
        },
        setToken(state, token) {
            state.token = token // 更新state中的token
            setToken(token) // 将token存到Cookie中
        }
    },
    actions: {
        // 登录
        async Login({ commit }, loginParams) {
            const resp = await login(loginParams)
            if (resp.error_code === 0) {
                // commit('setUserInfo', resp.response.user)
                commit('setToken', resp.response.token)
                // 动态路由可以再这里初始化
                // 目前操作直接跳转
                // router.push({ name: 'home' })
                return true
            }
            return false
        },

        async GetUserProfile({ commit }) {
            const resp = await getUserProfile()
            if (resp.error_code === 0) {
                commit('setUserInfo', resp.response)
                return true
            }
            return false
        },

        Logout({ commit }) {
            return new Promise((resolve, reject) => {
                console.log('reject', reject)
                // 退出登录无需请求接口
                commit('setToken', '')
                resolve()
            })
        }
    },
    getters: {
        userInfo(state) {
            return state.userInfo
        },
        token(state) {
            return state.token
        }
    }
}