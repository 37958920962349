import { createApp } from 'vue'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets

// 引入Element 组件
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';

import '@/styles/index.scss' // global css

import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './locales'

import '@/permission' // permission control

import '@/icons'
import SvgIcon from '@/components/SvgIcon/index.vue'// svg component

// createApp(App).component(SvgIcon).use(ElementPlus).use(store).use(router).mount('#app')
const app = createApp(App)
app.component('SvgIcon', SvgIcon)
app.use(ElementPlus,  { i18n: i18n.global.t }).use(i18n).use(store).use(router).mount('#app')
