export const route = {
  InferenceRule: 'Inference Rules',
  Illness: 'ActionGuide',
  Answer: 'Answer',
  Employees: 'Employees',
  Survey: 'Survey Info',
  Question: 'Survey Question',
  Menus: 'Menus',
  User: 'User',
  Api: 'Api',
  Authority: 'Authority',
  Dashboard: 'Dashboard',
  SuperAdmin: 'Super Admin',
  Password: 'Update Password',
  Questionnaire: "Questionnaire Manage",
  Profile: "Account Info"
}