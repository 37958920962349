<template>
  <div class="navbar">
    <hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <breadcrumb id="hamburger-container" class="breadcrumb-container" />

    <div class="right-menu">

      <template v-if="device!=='mobile'">

        <LangSelect class="right-menu-item hover-effect" />

      </template>

      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <img :src="avatar" class="user-avatar" />
          <i class="el-icon-caret-bottom" />
        </div>
        <template #dropdown>
          <el-dropdown-menu class="user-dropdown">
            <router-link to="/">
              <el-dropdown-item> {{$t('route.Dashboard')}} </el-dropdown-item>
            </router-link>
            <!-- <a
              target="_blank"
              href="https://github.com/PanJiaChen/vue-admin-template/"
            >
              <el-dropdown-item>Github</el-dropdown-item>
            </a>
            <a
              target="_blank"
              href="https://panjiachen.github.io/vue-element-admin-site/#/"
            >
              <el-dropdown-item>Docs</el-dropdown-item>
            </a> -->
            <el-dropdown-item divided @click="logout">
              <span style="display: block">{{$t('login.logout')}} </span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import Hamburger from "@/components/Hamburger";
import LangSelect from '@/components/LangSelect';
import { useRoute, useRouter } from "vue-router";
import { computed } from "@vue/runtime-core";

export default {
  components: {
    Breadcrumb,
    Hamburger,
    LangSelect
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const avatar = computed(() => {
      console.log('avatar', store.getters["user/userInfo"].avatar)
      return store.getters["user/userInfo"].avatar || 'https://avatars.githubusercontent.com/u/14888437?s=60&v=4';
    });

    const sidebar = computed(() => {
      console.log('nv-si', store.getters["app/sidebar"])
      return store.getters["app/sidebar"];
    });

    const device = computed(() => {
      console.log("device", store.getters["app/device"])
      return store.getters["app/device"]
    })

    return {
      avatar,
      sidebar,
      device,
      toggleSideBar() {
        store.dispatch("app/toggleSideBar");
      },
      async logout() {
        await store.dispatch("user/Logout");
        router.push(`/login?redirect=${route.fullPath}`);
      },
    };
  },
};
</script>

<style lang="scss">
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;
      line-height: 50px;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
          // margin-right: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
