export const question = { 
    "description": "description", 
    "position": "position", 
    "required": "required", 
    "title": "title", 
    "type": "type",
    "answer_options": "answer options",
    "option": "option",
    "add_option": "add option",
    "question_type": "type",
    "questionnaire": "questionnaire",
    "organ_type": "organ"
}