<template>
  <el-dropdown trigger="click" class="international" @command="handleSetLanguage">
    <div>
      <svg-icon class-name="international-icon" icon-class="language" />
    </div>
    <template #dropdown>
        <el-dropdown-menu>
            <el-dropdown-item :disabled="language==='zh-CN'" command="zh-CN">
                中文
            </el-dropdown-item>
            <el-dropdown-item :disabled="language==='en-US'" command="en-US">
                English
            </el-dropdown-item>
        </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
export default {
    setup() {
        const store = useStore()
        const { locale } = useI18n()

        const language = computed(() => {
            return store.state.app.language
        })

        const handleSetLanguage = (lang) => {
            locale.value = lang
            store.dispatch('app/setLanguage', lang)
        }

        return {
            language,
            handleSetLanguage
        }
    }
}
</script>
