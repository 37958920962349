export const menus = {
    "component": "component",
    "hidden": "hidden",
    "icon": "icon",
    "name": "name",
    "path": "path",
    "position": "position",
    "redirect": "redirect",
    "title": "title",
    "node": "parent node",
    "create_submenu": "create submenu",
    "root": "create root menu"
}