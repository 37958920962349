import { computed, getCurrentInstance, onMounted } from "vue"
import { useStore } from "vuex"


export default {
  setup() {
    const store = useStore()
    const { proxy } = getCurrentInstance()
    const device = computed(() => {
      return store.getters['app/device']
    })
    const fixBugIniOS = () => {
      const $subMenu = proxy.$refs.subMenu
      if ($subMenu) {
        const handleMouseleave = $subMenu.handleMouseleave
        $subMenu.handleMouseleave = (e) => {
          if (device.value === 'mobile') {
            return
          }
          handleMouseleave(e)
        }
      }
    }
    onMounted(() => {
      fixBugIniOS()
    })
    return {
      device,
      fixBugIniOS,
    }
  }
}
