import router from './router'
import store from './store'
import { ElMessage } from 'element-plus'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
// import { getUserProfile } from "@/api/user";

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whitelist = ['/login'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
    // start progress bar
    NProgress.start()

    // set page title
    document.title = getPageTitle(to.meta.title || "test")

    // determine whether the user has logged in
    const hasToken = getToken()
    console.log('hasToken', hasToken)

    if (hasToken) {
        console.log('to path', to.path)
        if (to.path === '/login') {
            // if is logged in, redirect to the home page
            next({ path: '/' })
            NProgress.done()
        } else {
            const hasGetUserInfo = store.getters['user/userInfo']
            if (hasGetUserInfo.name) {
                next()
            } else {
                // get user info
                // const res = await getUserProfile()
                const res = await store.dispatch("user/GetUserProfile")
                console.log('get user profile res', res)
                if (res) {
                    next()
                } else {
                    // 根据业务需求，是否需要刷新token
                    ElMessage.error('get user profile error')
                    next(`/login?redirect=${to.path}`)
                    NProgress.done()
                }
            }
        }
    } else {
        /* has no token*/
        if (whitelist.indexOf(to.path) !== -1) {
            next()
        } else {
            // other pages that do not have permission to access are redirected to the login page.
            next(`/login?redirect=${to.path}`)
            NProgress.done()
        }
    }
})

router.afterEach(() => {
    // finish progress bar
    NProgress.done()
})