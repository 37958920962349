export const route = {
  InferenceRule: '推理规则',
  Illness: '行动指南',
  Answer: '问卷答案',
  Employees: '员工信息',
  Survey: '问卷信息',
  Question: '问卷问题',
  Menus: '菜单管理',
  User: '用户管理',
  Api: 'Api管理',
  Authority: '角色管理',
  Dashboard: '首页',
  SuperAdmin: '超级管理员',
  Password: '修改密码',
  Questionnaire: "问卷管理",
  Profile: "账号信息"
}