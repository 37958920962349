export const profile = {
    header: "account information",
    avatar: "avatar",
    nickname: "nickname",
    email: "email",
    mobile: "mobile",
    remark: "remark",
    old_pwd: "origin password",
    new_pwd: "new password",
    new_confirm_pwd: "confirm password",
    edit_info: "edit info",
    modify_password: "modify password"
}