export const profile = {
    header: "账号信息",
    avatar: "头像",
    nickname: "昵称",
    email: "邮箱",
    mobile: "手机号",
    remark: "备注",
    old_pwd: "原密码",
    new_pwd: "新密码",
    new_confirm_pwd: "再次确认",
    edit_info: "编辑信息",
    modify_password: "修改密码"
}