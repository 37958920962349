export const question = { 
    "description": "题目备注", 
    "position": "题目排序", 
    "required": "是否必答", 
    "title": "题目标题", 
    "type": "题目类型",
    "answer_options": "答案选项",
    "option": "选项",
    "add_option": "新增选项",
    "question_type": "问题类型",
    "questionnaire": "所属问卷",
    "organ_type": "所属器官"
}