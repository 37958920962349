export const common = {
    detail: "详情",
    edit: "编辑",
    delete: "删除",
    create: "创建",
    search_placeholder: "请输入内容",
    action: "操作",
    search: "搜索",
    cancel: "取消",
    save: "保存",
    input_validate: "必填项",
    number_validate: "必须为数字",
    datetime: "选择日期和时间",
    number: "编号",
    set_authority: "设置权限",
    id: "id",
    create_prompt: "创建成功",
    delete_prompt: "删除成功",
    update_prompt: "更新成功",
    reset_prompt: "重置成功",
    reset_password: "重置密码",
    user: "用户",
    password: "密码",
    sync_prompt: "同步成功",
    search_text: "检索内容",
    select: "请选择"
}