import axios from 'axios'
import store from '@/store/index'
import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url

    // local
    // baseURL: "http://localhost:9999/server", // local config

    // dev
    // baseURL: "https://boehringer1-891513930.cn-northwest-1.elb.amazonaws.com.cn/server", // dev:aws alb dns config
    // baseURL: "https://ds-pig.analyticservice.net/server/", // dev:domain config

    // pro
    // baseURL: "https://internal-swine-ai-alb-1372827096.cn-northwest-1.elb.amazonaws.com.cn/server", // pro: aws alb dns config
    baseURL: "https://swine-ai.boehringer-ingelheim.cn/server", // pro: domain config

    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(config => {
    // do something before request is sent

    if (store.getters['user/token']) {
        // let each request carry token
        // ['X-Token'] is a custom headers key
        // please modify it according to the actual situation
        config.headers = {
            'Content-Type': 'application/json',
            'X-Token': getToken(),
        }
    }
    return config
}, error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error);
})


// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */
    response => {
        return response.data
    },
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

export default service
