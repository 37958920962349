export const common = {
    detail: "Detail",
    edit: "Edit",
    delete: "Delete",
    create: "Create",
    search_placeholder: "please input search content",
    action: "Action",
    search: "Search",
    cancel: "Cancel",
    save: "Save",
    input: "please input",
    input_validate: "required",
    number_validate: "must be a number",
    datetime: "select datetime",
    number: "number",
    set_authority: "set permissions",
    id: "id",
    create_prompt: "create success",
    delete_prompt: "delete success",
    update_prompt: "update success",
    reset_prompt: "reset success",
    reset_password: "reset password",
    user: "user",
    password: "password",
    sync_prompt: "sync success",
    search_text: "search content",
    select: "please select"
}