// import { getLanguage } from '@/utils/locale'

import Cookies from 'js-cookie'
import { createI18n } from 'vue-i18n'
import elementEnLocale from 'element-plus/lib/locale/lang/en'
import elementZhLocale from 'element-plus/lib/locale/lang/zh-cn'
import enLocale from './en-US'
import zhLocale from './zh-CN'

const messages = {
    'en-US': {
        ...enLocale,
        ...elementEnLocale
    },
    'zh-CN': {
        ...zhLocale,
        ...elementZhLocale
    }
}

export const getLanguage = () => {
    const cookieLanguage = Cookies.get('language')
    if (cookieLanguage) return cookieLanguage

    // if has not choose language
    const language = navigator.language
    const locales = Object.keys(messages)
    for(const locale  of locales) {
        if (language.indexOf(locale) > -1) {
            return locale
        }
    }
    return 'zh-CN'
}

const i18n = createI18n({
    locale: getLanguage(),
    fallbackLocale: "zh-CN",
    messages,
})

export default i18n