<template>
  <div :class="{ 'has-logo': showLogo }">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        background-color="#304156"
        text-color="#bfcbd9"
        :unique-opened="false"
        active-text-color="#409EFF"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="route in routes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { useStore } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
// import variables from "@/styles/variables.scss";
import { computed } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";

export default {
  components: { SidebarItem, Logo },
  setup() {
    const store = useStore();
    const sidebar = computed(() => {
      return store.getters["app/sidebar"];
    });

    const route = useRoute();
    const router = useRouter();

    const routes = computed(() => {
      return router.options.routes;
    });

    const activeMenu = computed(() => {
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    })

    const showLogo = computed(() => {
      return store.getters["settings/sidebarLogo"];
    });

    // const variables = computed(() => {
    //   return variables;
    // });

    const isCollapse = computed(() => {
      return !sidebar.value.opened;
    });

    return {
      routes,
      activeMenu,
      showLogo,
      isCollapse,
      // variables
    };
  },
};
</script>
