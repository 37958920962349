import { onBeforeMount, onBeforeUnmount, onMounted, watch, getCurrentInstance } from "vue";
import { useStore } from "vuex";

const { body } = document
const WIDTH = 992 // refer to Bootstrap's responsive design

export default {

  setup() {

    const route = useStore()

    const store = useStore()

    const { proxy } = getCurrentInstance()

    watch(() => route, () => {
      // console.debug(`MyCoolComponent - watch route.name changed to ${route.name}`);
      if (proxy.device === 'mobile' && proxy.sidebar.opened) {
        store.dispatch('app/closeSideBar', { withoutAnimation: false })
      }
    }, { immediate: true });

    onBeforeMount(() => {
      window.addEventListener('resize', $_resizeHandler)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', $_resizeHandler)
    })

    onMounted(() => {
      const isMobile = $_isMobile()
      if (isMobile) {
        store.dispatch('app/toggleDevice', 'mobile')
        store.dispatch('app/closeSideBar', { withoutAnimation: true })
      }
    });

    const $_isMobile = () => {
      const rect = body.getBoundingClientRect()
      return rect.width - 1 < WIDTH
    }

    const $_resizeHandler = () => {
      if (!document.hidden) {
        const isMobile = this.$_isMobile()
        store.dispatch('app/toggleDevice', isMobile ? 'mobile' : 'desktop')

        if (isMobile) {
          store.dispatch('app/closeSideBar', { withoutAnimation: true })
        }
      }
    }

    return {
      $_isMobile,
      $_resizeHandler
    }
  }
}
