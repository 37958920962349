export const menus = {
    "component": "组件路径",
    "hidden": "是否隐藏",
    "icon": "菜单图标",
    "name": "路由名字",
    "path": "路由路径",
    "position": "排序",
    "redirect": "重定向地址",
    "title": "菜单名",
    "node": "父节点",
    "create_submenu": "创建子菜单",
    "root": "新增根菜单"
}