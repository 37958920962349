export const login = {
    title: "Login Form",
    login: "Login",
    email_placeholder: "please input email",
    password_placeholder: "please input password",
    logout: "Log Out",
    captcha_placeholder: "please input captcha",
    success: "login success",
    fail: "login fail",
    forget_password: "forget password?"
}