export const survey = { 
    "description": "description", 
    "position": "position", 
    "status": "status", 
    "title": "title",
    "creator": "creator",
    "preview": "preview",
    "import": "import survey",
    "prompt": "missing header information",
    "enabled": "enable",
    "disabled": "disable"
}